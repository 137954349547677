import React from "react"

import Update from "../components/Update"

// Dashboard
import DashboardIndex from "../pages/dashboard/Index"

// Authentication related pages
import Login from "../pages/authentication/Login"
import ForgotPassword from "pages/authentication/ForgotPassword"
import Cheque from "pages/authentication/Cheque"

import ChequesIndex from "../pages/pos/cheques/Index"
import DebitCreditIndex from "../pages/pos/debitCredit/Index"

import DocumentsInIndex from "../pages/pos/documentsIn/Index"
import DocumentsInCreateUpdate from "../pages/pos/documentsIn/CreateUpdate"
import DocumentsInPreview from "../pages/pos/documentsIn/Preview"

import DocumentsOutIndex from "../pages/pos/documentsOut/Index"
import DocumentsOutCreateUpdate from "../pages/pos/documentsOut/CreateUpdate"
import DocumentsPreview from "../pages/pos/documentsOut/Preview"

import WriteOffIndex from "../pages/pos/writeOff/Index"
import WriteOffCreateUpdate from "../pages/pos/writeOff/CreateUpdate"
import WriteOffPreview from "../pages/pos/writeOff/Preview"

import GroupingIndex from "../pages/pos/grouping/Index"
import GroupingCreateUpdate from "../pages/pos/grouping/CreateUpdate"

import DealerIndex from "../pages/general/dealer/Index"
import DealerCreateUpdate from "../pages/general/dealer/CreateUpdate"

import HistoryIndex from "../pages/pos/history/Index"

import InventoryIndex from "../pages/pos/inventory/Index"
import InventoryCreateUpdate from "../pages/pos/inventory/CreateUpdate"
import InventorySocketCreateUpdate from "../pages/pos/inventory/SocketCreateUpdate"
import InventoryPreview from "../pages/pos/inventory/Preview"

import NomenclatureIndex from "../pages/pos/nomenclature/Index"

import RevaluationIndex from "../pages/pos/revaluation/Index"

import NotificationsIndex from "../pages/pos/notifications/Index"

import PriceTagsIndex from "../pages/pos/priceTags/Index"
import ReportsIndex from "../pages/pos/reports/Index"

import ScaleIndex from "../pages/pos/scale/Index"
import ScaleCreateUpdate from "../pages/pos/scale/CreateUpdate"

import TransferIndex from "../pages/pos/transfer/Index"
import TransferCreateUpdate from "../pages/pos/transfer/CreateUpdate"
import TransferPreview from "../pages/pos/transfer/Preview"

import TransferPosIndex from "../pages/pos/transferPos/Index"
import TransferPosCreateUpdate from "../pages/pos/transferPos/posToWms/CreateUpdate"
import TransferPosPreview from "../pages/pos/transferPos/posToWms/Preview"
import TransferPosToWmsPreview from "../pages/pos/transferPos/wmsToPos/Preview"

import OrderIndex from "../pages/pos/orders/Index"
import OrderCreateUpdate from "../pages/pos/orders/CreateUpdate"
import OrderPreview from "../pages/pos/orders/Preview"

import WmsOrderIndex from "../pages/wms/orders/Index"
import WmsOrderPreview from "../pages/wms/orders/Preview"

// import TransferWmsIndex from "../pages/pos/transfer/Index"
// import TransferWmsCreateUpdate from "../pages/pos/transfer/CreateUpdate"
// import TransferWmsPreview from "../pages/pos/transfer/Preview"

// WMS

import WmsDashboardIndex from "../pages/wms/dashboard/Index"

import WmsChequesIndex from "../pages/wms/cheques/Index"
import WmsDebitCreditIndex from "../pages/wms/debitCredit/Index"

import WmsDocumentsInIndex from "../pages/wms/documentsIn/Index"
import WmsDocumentsInCreateUpdate from "../pages/wms/documentsIn/CreateUpdate"
import WmsDocumentsInPreview from "../pages/wms/documentsIn/Preview"

import WmsDocumentsOutIndex from "../pages/wms/documentsOut/Index"
import WmsDocumentsOutCreateUpdate from "../pages/wms/documentsOut/CreateUpdate"
import WmsDocumentsPreview from "../pages/wms/documentsOut/Preview"

import WmsWriteOffIndex from "../pages/wms/writeOff/Index"
import WmsWriteOffCreateUpdate from "../pages/wms/writeOff/CreateUpdate"
import WmsWriteOffPreview from "../pages/wms/writeOff/Preview"

import WmsGroupingIndex from "../pages/wms/grouping/Index"
import WmsGroupingCreateUpdate from "../pages/wms/grouping/CreateUpdate"

import WmsInventoryIndex from "../pages/wms/inventory/Index"
import WmsInventoryCreateUpdate from "../pages/wms/inventory/CreateUpdate"
import WmsInventorySocketCreateUpdate from "../pages/wms/inventory/SocketCreateUpdate"
import WmsInventoryPreview from "../pages/wms/inventory/Preview"

import WmsRevaluationIndex from "../pages/wms/revaluation/Index"

import WmsNotificationsIndex from "../pages/wms/notifications/Index"

import WmsPriceTagsIndex from "../pages/wms/priceTags/Index"
import WmsReportsIndex from "../pages/wms/reports/Index"

import WmsScaleIndex from "../pages/wms/scale/Index"
import WmsScaleCreateUpdate from "../pages/wms/scale/CreateUpdate"

import WmsTransferIndex from "../pages/wms/transfer/Index"
import WmsTransferCreateUpdate from "../pages/wms/transfer/CreateUpdate"
import WmsTransferPreview from "../pages/wms/transfer/Preview"

import WmsTransferWmsIndex from "../pages/wms/transferWms/Index"
import WmsTransferWmsCreateUpdate from "../pages/wms/transferWms/wmsToPos/CreateUpdate"
import WmsTransferWmsPreview from "../pages/wms/transferWms/posToWms/Preview"
import WmsTransferPosPreview from "../pages/wms/transferWms/wmsToPos/Preview"

import WmsCashboxIndex from "../pages/wms/cashbox/Index"
import WmsShiftIndex from "../pages/wms/cashbox/Shift"

import WmsWorkersIndex from "../pages/wms/myWorkers/Index"

// GENERAL
import Settings from "../pages/general/settings/Index"

import PosIndex from "../pages/general/poses/Index"
import PosCreateUpdate from "../pages/general/poses/CreateUpdate"

import WmsIndex from "../pages/general/wms/Index"
import WmsCreateUpdate from "../pages/general/wms/CreateUpdate"

import CashboxIndex from "../pages/general/cashbox/Index"
import ShiftIndex from "../pages/general/cashbox/Shift"

import WorkersIndex from "../pages/general/myWorkers/Index"

import SuppliersIndex from "../pages/general/suppliers/Index"

import CurrencyIndex from "../pages/general/currency/Index"

import ClientsIndex from "../pages/general/clients/Index"
import MyOrganizationUpdate from "../pages/general/myOrganization/CreateUpdate"

import ProductsIndex from "../pages/general/products/Index"
import ProductsCreateUpdate from "../pages/general/products/CreateUpdate"

import ProductCategoriesIndex from "../pages/general/productCategories/Index"
import ProductCategoriesCreateUpdate from "../pages/general/productCategories/CreateUpdate"

// ADMIN
import Test from "../pages/test"
import BarcodeForm from "pages/pos/Inventarization/BarcodeForm"

const userRoutes = [
	{ path: "/dashboard", component: <DashboardIndex /> },

	{ path: "/cheques", component: <ChequesIndex /> },

	{ path: "/debit-credit", component: <DebitCreditIndex /> },

	{ path: "/documents-in", component: <DocumentsInIndex /> },
	{ path: "/documents-in/create", component: <DocumentsInCreateUpdate /> },
	{ path: "/documents-in/update/:id", component: <DocumentsInCreateUpdate /> },
	{ path: "/documents-in/preview/:id", component: <DocumentsInPreview /> },

	{ path: "/documents-out", component: <DocumentsOutIndex /> },
	{ path: "/documents-out/create", component: <DocumentsOutCreateUpdate /> },
	{ path: "/documents-out/preview/:id", component: <DocumentsPreview /> },

	{ path: "/write-off", component: <WriteOffIndex /> },
	{ path: "/write-off/create", component: <WriteOffCreateUpdate /> },
	{ path: "/write-off/preview/:id", component: <WriteOffPreview /> },

	{ path: "/grouping", component: <GroupingIndex /> },
	{ path: "/grouping/create", component: <GroupingCreateUpdate /> },
	{ path: "/grouping/update/:paramsPosId/:paramsProductGroupId", component: <GroupingCreateUpdate /> },

	{ path: "/dealer", component: <DealerIndex /> },
	{ path: "/dealer/create", component: <DealerCreateUpdate /> },
	{ path: "/dealer/update/:id", component: <DealerCreateUpdate /> },

	{ path: "/history", component: <HistoryIndex /> },

	{ path: "/inventory", component: <InventoryIndex /> },
	{ path: "/inventory/create", component: <InventoryCreateUpdate /> },
	{ path: "/inventory/update/:id", component: <InventoryCreateUpdate /> },
	{ path: "/inventory/create-socket", component: <InventorySocketCreateUpdate /> },
	{ path: "/inventory/update-socket/:id", component: <InventorySocketCreateUpdate /> },
	{
		path: "/inventory/preview/:id", component: <InventoryPreview />
	},

	{ path: "/nomenclature", component: <NomenclatureIndex /> },

	{ path: "/revaluation", component: <RevaluationIndex /> },

	{ path: "/cashbox", component: <CashboxIndex /> },
	{ path: "/cashbox/shift/:id?", component: <ShiftIndex /> },

	{ path: "/notifications", component: <NotificationsIndex /> },

	{ path: "/workers", component: <WorkersIndex /> },

	{ path: "/price-tags", component: <PriceTagsIndex /> },

	{ path: "/reports", component: <ReportsIndex /> },
	{ path: "/reports/organizations/:id", component: <ReportsIndex /> },
	{ path: "/reports/clients/:id", component: <ReportsIndex /> },

	{ path: "/scale", component: <ScaleIndex /> },
	{ path: "/scale/create", component: <ScaleCreateUpdate /> },

	{ path: "/transfer", component: <TransferIndex /> },
	{ path: "/transfer/create", component: <TransferCreateUpdate /> },
	{ path: "/transfer/update/:id", component: <TransferCreateUpdate /> },
	{ path: "/transfer/preview/:id", component: <TransferPreview /> },

	{ path: "/pos-transfer", component: <TransferPosIndex /> },
	{ path: "/pos-transfer/create", component: <TransferPosCreateUpdate /> },
	{ path: "/pos-transfer/update/:id", component: <TransferPosCreateUpdate /> },
	{ path: "/pos-transfer/preview/:id", component: <TransferPosPreview /> },
	{ path: "/pos-wms-transfer/preview/:id", component: <TransferPosToWmsPreview /> },

	{ path: "/orders", component: <OrderIndex /> },
	{ path: "/orders/create", component: <OrderCreateUpdate /> },
	{ path: "/orders/update/:id", component: <OrderCreateUpdate /> },
	{ path: "/orders/preview/:id", component: <OrderPreview /> },

	// WMS
	{ path: "/wms/orders", component: <WmsOrderIndex /> },
	{ path: "/wms/orders/preview/:id", component: <WmsOrderPreview /> },

	{ path: "/wms/dashboard", component: <WmsDashboardIndex /> },

	{ path: "/wms/cheques", component: <WmsChequesIndex /> },

	{ path: "/wms/debit-credit", component: <WmsDebitCreditIndex /> },

	{ path: "/wms/documents-in", component: <WmsDocumentsInIndex /> },
	{ path: "/wms/documents-in/create", component: <WmsDocumentsInCreateUpdate /> },
	{ path: "/wms/documents-in/update/:id", component: <WmsDocumentsInCreateUpdate /> },
	{ path: "/wms/documents-in/preview/:id", component: <WmsDocumentsInPreview /> },

	{ path: "/wms/documents-out", component: <WmsDocumentsOutIndex /> },
	{ path: "/wms/documents-out/create", component: <WmsDocumentsOutCreateUpdate /> },
	{ path: "/wms/documents-out/preview/:id", component: <WmsDocumentsPreview /> },

	{ path: "/wms/write-off", component: <WmsWriteOffIndex /> },
	{ path: "/wms/write-off/create", component: <WmsWriteOffCreateUpdate /> },
	{ path: "/wms/write-off/preview/:id", component: <WmsWriteOffPreview /> },

	{ path: "/wms/grouping", component: <WmsGroupingIndex /> },
	{ path: "/wms/grouping/create", component: <WmsGroupingCreateUpdate /> },
	{ path: "/wms/grouping/update/:paramsWmsId/:paramsProductGroupId", component: <WmsGroupingCreateUpdate /> },

	{ path: "/wms/inventory", component: <WmsInventoryIndex /> },
	{ path: "/wms/inventory/create", component: <WmsInventoryCreateUpdate /> },
	{ path: "/wms/inventory/update/:id", component: <WmsInventoryCreateUpdate /> },
	{ path: "/wms/inventory/create-socket", component: <WmsInventorySocketCreateUpdate /> },
	{ path: "/wms/inventory/update-socket/:id", component: <WmsInventorySocketCreateUpdate /> },
	{
		path: "/wms/inventory/preview/:id", component: <WmsInventoryPreview />
	},

	{ path: "/wms/revaluation", component: <WmsRevaluationIndex /> },

	{ path: "/wms-cashbox", component: <WmsCashboxIndex /> },
	{ path: "/wms-cashbox/shift/:id?", component: <WmsShiftIndex /> },

	{ path: "/wms/notifications", component: <WmsNotificationsIndex /> },

	{ path: "/wms/workers", component: <WmsWorkersIndex /> },

	{ path: "/wms/price-tags", component: <WmsPriceTagsIndex /> },

	{ path: "/wms/reports", component: <WmsReportsIndex /> },
	{ path: "/wms/reports/organizations/:id", component: <WmsReportsIndex /> },
	{ path: "/wms/reports/clients/:id", component: <WmsReportsIndex /> },

	{ path: "/wms/scale", component: <WmsScaleIndex /> },
	{ path: "/wms/scale/create", component: <WmsScaleCreateUpdate /> },

	{ path: "/wms/transfer", component: <WmsTransferIndex /> },
	{ path: "/wms/transfer/create", component: <WmsTransferCreateUpdate /> },
	{ path: "/wms/transfer/update/:id", component: <WmsTransferCreateUpdate /> },
	{ path: "/wms/transfer/preview/:id", component: <WmsTransferPreview /> },

	{ path: "/wms/wms-transfer", component: <WmsTransferWmsIndex /> },
	{ path: "/wms/wms-transfer/create", component: <WmsTransferWmsCreateUpdate /> },
	{ path: "/wms/wms-transfer/update/:id", component: <WmsTransferWmsCreateUpdate /> },
	{ path: "/wms/wms-transfer/preview/:id", component: <WmsTransferWmsPreview /> },
	{ path: "/wms/pos-transfer/preview/:id", component: <WmsTransferPosPreview /> },

	// GENERAL
	{ path: "/settings", component: <Settings /> },

	{ path: "/pos", component: <PosIndex /> },
	{ path: "/pos-create", component: <PosCreateUpdate /> },
	{ path: "/pos-update/:id", component: <PosCreateUpdate /> },

	{ path: "/warehouse", component: <WmsIndex /> },
	{ path: "/warehouse-create", component: <WmsCreateUpdate /> },
	{ path: "/warehouse-update/:id", component: <WmsCreateUpdate /> },

	{ path: "/clients", component: <ClientsIndex /> },

	{ path: "/my-organization", component: <MyOrganizationUpdate /> },

	{ path: "/suppliers", component: <SuppliersIndex /> },

	{ path: "/currency", component: <CurrencyIndex /> },

	{ path: "/products", component: <ProductsIndex /> },
	{ path: "/products-create", component: <ProductsCreateUpdate /> },
	{ path: "/products-update/:id", component: <ProductsCreateUpdate /> },
	{ path: "/product-categories", component: <ProductCategoriesIndex /> },
	{ path: "/product-categories-create", component: <ProductCategoriesCreateUpdate /> },
	{ path: "/product-categories-update/:id", component: <ProductCategoriesCreateUpdate /> },


	// ADMIN
	{ path: "/test", component: <Test /> },

	{ path: "/update/:id", component: <Update /> },

	// this route should be at the end of all other routes
	// { path: "/", exact: true, component: () => <Navigate to="/dashboard" /> },
	// 404 route not found
	// { path: "*", exact: true, component: () => <Navigate to="/dashboard" /> },
]

const authRoutes = [
	{ path: "/auth/login", component: <Login /> },
	{ path: "/forgot-password", component: <ForgotPassword /> },
	{ path: "/cheque/:shiftId/:chequeNumber", component: <Cheque /> },
	{ path: "/inventorization", component: <BarcodeForm /> },
]

export { userRoutes, authRoutes }