export let exportMenu2 = [
	// {
	// 	url: "/my-organization",
	// 	i18n: "MY_ORGANIZATION",
	// 	icon: "uil-university",
	// 	active: false,
	// },
	{
		icon: "uil-book-open",
		active: false,
		children: [
			{
				url: "/workers",
				i18n: "MY_USERS",
				icon: "uil-user-square",
				active: false,
			},
			{
				url: "/pos",
				i18n: "POS",
				icon: "uil-shop",
				active: false,
			},
			{
				url: "/cashbox",
				i18n: "CASHBOXES",
				icon: "uil-store",
				active: false,
			},
			{
				url: "/products",
				i18n: "PRODUCTS",
				icon: "uil-shopping-cart",
				active: false,
			},
			{
				url: "/dealer",
				i18n: "DEALER",
				icon: "uil-shopping-cart",
				active: false,
			},
			{
				url: "/product-categories",
				i18n: "ADDITIONAL_PRODUCT_CATEGORIES",
				icon: "uil-shopping-cart",
				active: false,
			},
			{
				url: "/suppliers",
				i18n: "SUPPLIERS",
				icon: "uil-truck-loading",
				active: false,
			},
			{
				url: "/clients",
				i18n: "CLIENTS",
				icon: "uil-users-alt",
				active: false,
			},
			{
				url: "/currency",
				i18n: "CURRENCY",
				icon: "uil-money-bill",
				active: false,
			},
		]
	}
]